import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={"https://img1.picmix.com/output/stamp/thumb/7/7/8/3/2243877_e23b6.gif"} className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;
